html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'Bw Modelica', 'Roboto', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

div#root {
  height: 100%;
  display: flex;
}

* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

*:focus-visible {
  outline: 2px solid #4d90fe;
}

[data-component='modal'] > :focus-visible {
  outline: none;
}
